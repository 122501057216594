import { ColumnId } from "@organisms/Table/Table.types"

export const columnNameByColumnId: {
    [key in ColumnId]?: string
} = {
    id: "ID",
    client_name: "Client",
    state_label: "State",
    status_label: "Status",
    service_name: "Service",
    service_location_name: "Location Name",
    service_location_address: "Location Address",
    total: "Total",
    date_due: "Due Date",
    down_payment_status: "Down Payment",
    last_status_transition_time: "Status Changed",
    last_sent_at: "Sent",
    last_approved_at: "Approved",
    last_won_at: "Won",
    last_lost_at: "Lost",
    last_cancelled_at: "Cancelled",
    last_modified_at: "Last Modified",
    last_viewed: "Seen",
    job_type: "Job Type",
    assigned_technicians: "Techs",
    date_received: "Date Received",
    estimated_arrival_time: "Scheduled For",
    estimated_duration: "Duration",
    last_scheduled_at: "Scheduled",
    last_active_at: "Active",
    last_paused_at: "Paused",
    last_on_hold_at: "On Hold",
    last_completed_at: "Completed",
    last_finalized_at: "Finalized",
    amount_due: "Amt. Due",
    last_paid_at: "Paid",
    last_voided_at: "Voided",
    last_forgiven_at: "Forgiven",
    vendor_name: "Vendor",
    item_count: "Items",
    subtotal: "Amount",
    shipping: "Shipping",
    preferred_shipping: "Shipping Preference",
    tracking_number: "Tracking Number",
    associated_jobs: "Associated Jobs",
    date_issued: "Issue Date",
    payment_terms: "Payment Terms",
    last_acknowledged_at: "Acknowledged",
    last_in_transit_at: "In Transit",
    last_delivered_at: "Delivered",
    last_stocked_at: "Stocked",
    name: "Client",
    client_type_label: "Type",
    service_locations_display: "Location",
    default_invoice_net: "Terms",
    credit_limit: "Credit Limit",
    outstanding_balance: "Balance Due",
    current_estimates_count: "Current Est.",
    current_jobs_count: "Current Jobs",
    current_invoices_count: "Current Inv.",
    description: "Short Description",
    summary_template: "Summary",
    rate: "Rate",
    cost: "Cost",
    default_price: "Price",
    discount: "Discount",
    tax_rate: "Tax Rate",
    manufacturer: "OEM",
    part_number: "OEM Part #",
    default_job_duration: "Est. Duration",
    labels: "Labels",
    inventory_status: "Stock",
    account_id: "Account ID",
    billing_address_formatted: "Billing Address",
    website: "Website",
    current_purchase_order_count: "Current POs",
}

export const columnNameByIdVariations: {
    [key in ObjectType]?: {
        [key in ColumnId]?: string
    }
} = {
    estimate: {
        date_due: "Expires",
    },
    purchase_order: {
        estimated_arrival_time: "Est. Arrival",
    },
    pricebook_item: {
        status_label: "State",
    },
    vendor: {
        name: "Vendor",
    },
}
