import { CheckedState } from "@radix-ui/react-checkbox"
import { InvalidateQueryFilters, useQueryClient } from "@tanstack/react-query"
import { useEffect, useMemo, useState } from "react"

import useUser from "@hooks/useUser"

import { Checkbox } from "@atoms/index"

import GenericForm from "@molecules/Form/GenericForm/GenericForm"
import { Property } from "@molecules/index"

import { PropertyStack } from "@organisms/index"

import { Settings } from "@templates/index"

import {
    InventoryNotificationTopics,
    NotificationMethods,
} from "@pages/Settings/SettingsNotifications/SettingsNotification.types"
import {
    notificationPreferenceQueryKey,
    useNotificationPreferencesQuery,
} from "@pages/Settings/SettingsNotifications/SettingsNotifications.store"
import EmailNotificationTooltip from "@pages/Settings/SettingsNotifications/components/EmailNotificationTooltip/EmailNotificationTooltip"
import NotificationHeader from "@pages/Settings/SettingsNotifications/components/NotificationHeader/NotificationHeader"
import { useNotificationMethodsQuery } from "@pages/Settings/SettingsNotifications/components/NotificationMethodsSection/NotificationMethodsSection.store"
import SMSNotificationTooltip from "@pages/Settings/SettingsNotifications/components/SMSNotificationTooltip/SMSNotificationTooltip"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

export default function InventoryNotificationsForm() {
    const { user } = useUser()

    const [shouldEnableAllEmailNotifications, setShouldEnableAllEmailNotifications] = useState<boolean>(false)
    const [shouldDisableAllEmailNotifications, setShouldDisableAllEmailNotifications] = useState<boolean>(false)
    const [shouldEnableAllSmsNotifications, setShouldEnableAllSmsNotifications] = useState<boolean>(false)
    const [shouldDisableAllSmsNotifications, setShouldDisableAllSmsNotifications] = useState<boolean>(false)
    const [shouldSetDefaultFormData, setShouldSetDefaultFormData] = useState<boolean>(false)

    const { topicPreference: inventoryNotificationPreference, isLoading: isLoadingData } =
        useNotificationPreferencesQuery<InventoryNotificationTopics>("inventory")

    const { data: defaultMethodsPreference } = useNotificationMethodsQuery()

    const queryClient = useQueryClient()

    useEffect(() => {
        if (!isLoadingData && !!inventoryNotificationPreference) {
            setShouldSetDefaultFormData(true)
        }
    }, [isLoadingData])

    const handleToggleAllNotifications = (method: NotificationMethods, shouldEnableAllNotifications: CheckedState) => {
        if (method === "email") {
            setShouldDisableAllEmailNotifications(!shouldEnableAllNotifications)
            setShouldEnableAllEmailNotifications(!!shouldEnableAllNotifications)
        } else {
            setShouldDisableAllSmsNotifications(!shouldEnableAllNotifications)
            setShouldEnableAllSmsNotifications(!!shouldEnableAllNotifications)
        }
    }

    const phoneNotificationsDisabled = !user?.phone || !user?.phone_verified || !defaultMethodsPreference?.contact_sms
    const emailNotificationsDisabled =
        !user?.email || !user?.email_verified || !defaultMethodsPreference?.contact_email

    const prepareFieldset = (
        defaultFieldset: InventoryNotificationTopics,
        method: NotificationMethods,
        value: boolean,
    ) => {
        return Object.keys(defaultFieldset).reduce((acc, key) => {
            const notificationKey = key as keyof InventoryNotificationTopics
            if (notificationKey.endsWith(`_${method}`)) {
                acc[notificationKey] = value
            }
            return acc
        }, {} as InventoryNotificationTopics)
    }

    const formValues = useMemo(() => {
        if (shouldEnableAllEmailNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "email", true)
        } else if (shouldEnableAllSmsNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "sms", true)
        } else if (shouldDisableAllEmailNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "email", false)
        } else if (shouldDisableAllSmsNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "sms", false)
        } else if (shouldSetDefaultFormData) {
            return inventoryNotificationPreference
        } else {
            return undefined
        }
    }, [
        shouldSetDefaultFormData,
        shouldEnableAllEmailNotifications,
        shouldDisableAllEmailNotifications,
        shouldEnableAllSmsNotifications,
        shouldDisableAllSmsNotifications,
    ])

    const mutationTriggerPayload = useMemo(() => {
        if (shouldEnableAllEmailNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "email", true)
        } else if (shouldEnableAllSmsNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "sms", true)
        } else if (shouldDisableAllEmailNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "email", false)
        } else if (shouldDisableAllSmsNotifications) {
            return prepareFieldset(inventoryNotificationPreference, "sms", false)
        } else {
            return undefined
        }
    }, [
        shouldEnableAllEmailNotifications,
        shouldDisableAllEmailNotifications,
        shouldEnableAllSmsNotifications,
        shouldDisableAllSmsNotifications,
    ])

    const resetNotificationStates = () => {
        setShouldEnableAllEmailNotifications(false)
        setShouldDisableAllEmailNotifications(false)
        setShouldEnableAllSmsNotifications(false)
        setShouldDisableAllSmsNotifications(false)
    }

    const onMutationSuccess = () => {
        void queryClient.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
    }

    const onAfterFormValuesSet = () => {
        resetNotificationStates()
        setShouldSetDefaultFormData(false)
    }

    return (
        <GenericForm<InventoryNotificationTopics, InventoryNotificationTopics, Error, InventoryNotificationTopics>
            formConfig={{
                defaultValues: undefined,
            }}
            mutationConfig={{
                genericErrorMessage: "Could not update notification preference",
                genericSuccessMessage: "Notification preference updated",
                method: "PATCH",
                endpoint: NOTIFICATION_ENDPOINTS.UPDATE_NOTIFICATION_PREFERENCES,
            }}
            onMutationSuccess={onMutationSuccess}
            mutationTriggerPayload={mutationTriggerPayload}
            formValues={formValues}
            onAfterFormValuesSet={onAfterFormValuesSet}
            isAutoSave={true}
        >
            {({ form }) => {
                const formValues = form.getValues()

                const emailValues = Object.entries(formValues)
                    .filter(([key]) => key.endsWith("_email"))
                    .map(([, value]) => value)

                const smsValues = Object.entries(formValues)
                    .filter(([key]) => key.endsWith("_sms"))
                    .map(([, value]) => value)

                const isAllNotificationsEmailEnabled = emailValues.every(Boolean)
                const isAllNotificationsSmsEnabled = smsValues.every(Boolean)
                const isSomeNotificationsEmailEnabled = emailValues.some(Boolean)
                const isSomeNotificationsSmsEnabled = smsValues.some(Boolean)

                return (
                    <Settings.NotificationConfigPage.Wrapper>
                        <NotificationHeader.Root>
                            <NotificationHeader.EmailCheckbox
                                isIndeterminate={isSomeNotificationsEmailEnabled && !isAllNotificationsEmailEnabled}
                                isChecked={isAllNotificationsEmailEnabled}
                                onChecked={(checked) => handleToggleAllNotifications("email", checked)}
                            />
                            <NotificationHeader.SMSCheckbox
                                isIndeterminate={isSomeNotificationsSmsEnabled && !isAllNotificationsSmsEnabled}
                                isChecked={isAllNotificationsSmsEnabled}
                                onChecked={(checked) => handleToggleAllNotifications("sms", checked)}
                            />
                        </NotificationHeader.Root>
                        <PropertyStack>
                            <Property.Root>
                                <Property.Content>
                                    <Property.Label>Low inventory digest</Property.Label>
                                    <Property.Subtitle>
                                        Get a daily summary of all parts that have low or depleted inventory.
                                    </Property.Subtitle>
                                </Property.Content>
                                <Property.Value>
                                    <Settings.NotificationConfigPage.Checkboxes>
                                        <EmailNotificationTooltip>
                                            <Checkbox<InventoryNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={emailNotificationsDisabled}
                                                isDisabled={isLoadingData || emailNotificationsDisabled}
                                                name="enable_inventory_notification_low_inventory_email"
                                                isControlled={true}
                                            />
                                        </EmailNotificationTooltip>
                                        <SMSNotificationTooltip>
                                            <Checkbox<InventoryNotificationTopics>
                                                size="md"
                                                colorScheme="gray"
                                                displayAsUnchecked={phoneNotificationsDisabled}
                                                isDisabled={isLoadingData || phoneNotificationsDisabled}
                                                name="enable_inventory_notification_low_inventory_sms"
                                                isControlled={true}
                                            />
                                        </SMSNotificationTooltip>
                                    </Settings.NotificationConfigPage.Checkboxes>
                                </Property.Value>
                            </Property.Root>
                        </PropertyStack>
                    </Settings.NotificationConfigPage.Wrapper>
                )
            }}
        </GenericForm>
    )
}
