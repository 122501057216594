import { EstimateStatuses } from "@legacy/core/utils/enums"

import useObjectDetailsRoutes from "@hooks/useObjectDetailsRoute"
import useSlugExtractor from "@hooks/useSlugExtractor"

import { Table } from "@organisms"
import { Cells, Columns } from "@organisms/Table/Table.types"
import { DownPaymentStatus } from "@organisms/Table/TableCells/DownPaymentCell/DownPaymentCell.types"
import { GetObjectDetailsRouteFunction, Tab } from "@organisms/Table/TableViewControls/TableViewTab/TableViewTab.types"

import { ESTIMATES_ROUTES } from "@routes/estimates"

export default function useEstimateTabs(): [Tab<Estimate>[], Tab<Estimate>[]] {
    const [tabCountEndpoint, tabListEndpoint] = useSlugExtractor([
        ESTIMATES_ROUTES.COUNT,
        ESTIMATES_ROUTES.LIGHTWEIGHT_LIST,
    ])

    const getTabObjectDetailsRoute = useObjectDetailsRoutes("Estimate") as GetObjectDetailsRouteFunction

    const headerByColumn = {
        id: "ID",
        client_name: "Client",
        state_label: "State",
        status_label: "Status",
        service_name: "Service",
        service_location_name: "Location Name",
        service_location_address: "Location Address",
        total: "Total",
        date_due: "Expires",
        down_payment_status: "Down Payment",
        last_status_transition_time: "Status Changed",
        last_sent_at: "Sent",
        last_approved_at: "Approved",
        last_won_at: "Won",
        last_lost_at: "Lost",
        last_cancelled_at: "Cancelled",
        last_modified_at: "Last Modified",
        last_viewed: "Seen",
    }

    const sortingEnabledByColumn = {
        id: true,
        client_name: true,
        state_label: false,
        status_label: true,
        service_name: true,
        service_location_name: true,
        service_location_address: true,
        total: true,
        date_due: true,
        down_payment_status: true,
        last_status_transition_time: false,
        last_sent_at: true,
        last_approved_at: true,
        last_won_at: true,
        last_lost_at: true,
        last_cancelled_at: true,
        last_modified_at: true,
        last_viewed: true,
    }

    const widthByColumn = {
        id: 96,
        client_name: 224,
        state_label: 128,
        status_label: 176,
        service_name: 224,
        service_location_name: 288,
        service_location_address: 288,
        total: 128,
        date_due: 144,
        down_payment_status: 160,
        last_status_transition_time: 160,
        last_sent_at: 144,
        last_approved_at: 144,
        last_won_at: 144,
        last_lost_at: 144,
        last_cancelled_at: 144,
        last_modified_at: 144,
        last_viewed: 96,
    }

    const pinningByColumn = {
        id: true,
        client_name: true,
        state_label: false,
        status_label: false,
        service_name: false,
        service_location_name: false,
        service_location_address: false,
        total: false,
        date_due: false,
        down_payment_status: false,
        last_status_transition_time: false,
        last_sent_at: false,
        last_approved_at: false,
        last_won_at: false,
        last_lost_at: false,
        last_cancelled_at: false,
        last_modified_at: false,
        last_viewed: false,
    }

    const cellByColumn: Cells<Estimate> = {
        id: ({ row }) => <Table.Cells.IDCell id={row.original.custom_id || row.original.id} />,
        client_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        state_label: ({ getValue }) => <Table.Cells.BadgesCell badges={[getValue()]} />,
        status_label: ({ getValue }) => <Table.Cells.StatusCell status={getValue()} />,
        service_name: ({ getValue }) => <Table.Cells.TruncatedTextCell content={getValue()} />,
        service_location_name: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        service_location_address: ({ getValue }) => (
            <Table.Cells.TruncatedTextCell content={getValue()} variant="secondary" />
        ),
        total: ({ getValue }) => <Table.Cells.NumericalDataCell type="currency" value={Number(getValue())} />,
        date_due: ({ getValue, row }) => (
            <Table.Cells.DateTimeCell value={getValue()} isDangerous={row.original.is_expired} />
        ),
        down_payment_status: ({ getValue }) => <Table.Cells.DownPaymentCell value={getValue() as DownPaymentStatus} />,
        last_status_transition_time: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_sent_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_approved_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_won_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_lost_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_cancelled_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_modified_at: ({ getValue }) => <Table.Cells.DateTimeCell value={getValue()} />,
        last_viewed: ({ getValue }) => <Table.Cells.SeenCell value={getValue()} />,
    }

    const columnByName: Columns<Estimate> = {
        id: {
            header: headerByColumn["id"],
            accessorKey: "id",
            id: "id",
            size: widthByColumn["id"],
            cell: cellByColumn["id"],
            enableSorting: sortingEnabledByColumn["id"],
            enablePinning: pinningByColumn["id"],
        },
        client_name: {
            header: headerByColumn["client_name"],
            accessorKey: "client_name",
            id: "client_name",
            size: widthByColumn["client_name"],
            cell: cellByColumn["client_name"],
            enableSorting: sortingEnabledByColumn["client_name"],
            enablePinning: pinningByColumn["client_name"],
        },
        state_label: {
            header: headerByColumn["state_label"],
            accessorKey: "state_label",
            id: "state_label",
            size: widthByColumn["state_label"],
            cell: cellByColumn["state_label"],
            enableSorting: sortingEnabledByColumn["state_label"],
            enablePinning: pinningByColumn["state_label"],
        },
        status_label: {
            header: headerByColumn["status_label"],
            accessorKey: "status_label",
            id: "status_label",
            size: widthByColumn["status_label"],
            cell: cellByColumn["status_label"],
            enableSorting: sortingEnabledByColumn["status_label"],
            enablePinning: pinningByColumn["status_label"],
        },
        service_name: {
            header: headerByColumn["service_name"],
            accessorKey: "service_name",
            id: "service_name",
            size: widthByColumn["service_name"],
            cell: cellByColumn["service_name"],
            enableSorting: sortingEnabledByColumn["service_name"],
            enablePinning: pinningByColumn["service_name"],
        },
        service_location_name: {
            header: headerByColumn["service_location_name"],
            accessorKey: "service_location_name",
            id: "service_location_name",
            size: widthByColumn["service_location_name"],
            cell: cellByColumn["service_location_name"],
            enableSorting: sortingEnabledByColumn["service_location_name"],
            enablePinning: pinningByColumn["service_location_name"],
        },
        service_location_address: {
            header: headerByColumn["service_location_address"],
            accessorKey: "service_location_address",
            id: "service_location_address",
            size: widthByColumn["service_location_address"],
            cell: cellByColumn["service_location_address"],
            enableSorting: sortingEnabledByColumn["service_location_address"],
            enablePinning: pinningByColumn["service_location_address"],
        },
        total: {
            header: headerByColumn["total"],
            accessorKey: "total",
            id: "total",
            size: widthByColumn["total"],
            cell: cellByColumn["total"],
            enableSorting: sortingEnabledByColumn["total"],
            enablePinning: pinningByColumn["total"],
        },
        date_due: {
            header: headerByColumn["date_due"],
            accessorKey: "date_due",
            id: "date_due",
            size: widthByColumn["date_due"],
            cell: cellByColumn["date_due"],
            enableSorting: sortingEnabledByColumn["date_due"],
            enablePinning: pinningByColumn["date_due"],
        },
        last_status_transition_time: {
            header: headerByColumn["last_status_transition_time"],
            accessorKey: "last_status_transition_time",
            id: "last_status_transition_time",
            size: widthByColumn["last_status_transition_time"],
            cell: cellByColumn["last_status_transition_time"],
            enableSorting: sortingEnabledByColumn["last_status_transition_time"],
            enablePinning: pinningByColumn["last_status_transition_time"],
        },
        last_modified_at: {
            header: headerByColumn["last_modified_at"],
            accessorKey: "last_modified_at",
            id: "last_modified_at",
            size: widthByColumn["last_modified_at"],
            cell: cellByColumn["last_modified_at"],
            enableSorting: sortingEnabledByColumn["last_modified_at"],
            enablePinning: pinningByColumn["last_modified_at"],
        },
        last_viewed: {
            header: headerByColumn["last_viewed"],
            accessorKey: "last_viewed",
            id: "last_viewed",
            size: widthByColumn["last_viewed"],
            cell: cellByColumn["last_viewed"],
            enableSorting: sortingEnabledByColumn["last_viewed"],
            enablePinning: pinningByColumn["last_viewed"],
        },
        last_cancelled_at: {
            header: headerByColumn["last_cancelled_at"],
            accessorKey: "last_cancelled_at",
            id: "last_cancelled_at",
            size: widthByColumn["last_cancelled_at"],
            cell: cellByColumn["last_cancelled_at"],
            enableSorting: sortingEnabledByColumn["last_cancelled_at"],
            enablePinning: pinningByColumn["last_cancelled_at"],
        },
        down_payment_status: {
            header: headerByColumn["down_payment_status"],
            accessorKey: "down_payment_status",
            id: "down_payment_status",
            size: widthByColumn["down_payment_status"],
            cell: cellByColumn["down_payment_status"],
            enableSorting: sortingEnabledByColumn["down_payment_status"],
            enablePinning: pinningByColumn["down_payment_status"],
        },
        last_sent_at: {
            header: headerByColumn["last_sent_at"],
            accessorKey: "last_sent_at",
            id: "last_sent_at",
            size: widthByColumn["last_sent_at"],
            cell: cellByColumn["last_sent_at"],
            enableSorting: sortingEnabledByColumn["last_sent_at"],
            enablePinning: pinningByColumn["last_sent_at"],
        },
        last_approved_at: {
            header: headerByColumn["last_approved_at"],
            accessorKey: "last_approved_at",
            id: "last_approved_at",
            size: widthByColumn["last_approved_at"],
            cell: cellByColumn["last_approved_at"],
            enableSorting: sortingEnabledByColumn["last_approved_at"],
            enablePinning: pinningByColumn["last_approved_at"],
        },
        last_won_at: {
            header: headerByColumn["last_won_at"],
            accessorKey: "last_won_at",
            id: "last_won_at",
            size: widthByColumn["last_won_at"],
            cell: cellByColumn["last_won_at"],
            enableSorting: sortingEnabledByColumn["last_won_at"],
            enablePinning: pinningByColumn["last_won_at"],
        },
        last_lost_at: {
            header: headerByColumn["last_lost_at"],
            accessorKey: "last_lost_at",
            id: "last_lost_at",
            size: widthByColumn["last_lost_at"],
            cell: cellByColumn["last_lost_at"],
            enableSorting: sortingEnabledByColumn["last_lost_at"],
            enablePinning: pinningByColumn["last_lost_at"],
        },
    }

    const openEstimatesTabs: Tab<Estimate>[] = [
        {
            title: "All Open",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.pending],
                ["status", EstimateStatuses.sent],
                ["status", EstimateStatuses.approved],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["state_label"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["date_due"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Pending",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.pending],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["date_due"],
                columnByName["last_modified_at"],
            ],
        },
        {
            title: "Sent",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.sent],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["date_due"],
                columnByName["last_sent_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Approved",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "open",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.approved],
                ["ordering", "date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["down_payment_status"],
                columnByName["last_approved_at"],
                columnByName["last_modified_at"],
            ],
        },
    ]

    const closedEstimatesTabs: Tab<Estimate>[] = [
        {
            title: "All Closed",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.won],
                ["status", EstimateStatuses.lost],
                ["status", EstimateStatuses.cancelled],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["state_label"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["down_payment_status"],
                columnByName["last_status_transition_time"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Won",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.won],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["down_payment_status"],
                columnByName["last_won_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Lost",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.lost],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["date_due"],
                columnByName["last_lost_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
        {
            title: "Cancelled",
            tabObjectName: "Estimate",
            showTab: true,
            objectsPhase: "closed",
            countEndpoint: tabCountEndpoint,
            listEndpoint: tabListEndpoint,
            getDetailsRoute: getTabObjectDetailsRoute,
            endpointKwargs: [
                ["status", EstimateStatuses.cancelled],
                ["ordering", "-date_due"],
            ],
            columnDefinition: [
                columnByName["id"],
                columnByName["client_name"],
                columnByName["status_label"],
                columnByName["service_name"],
                columnByName["service_location_name"],
                columnByName["service_location_address"],
                columnByName["total"],
                columnByName["date_due"],
                columnByName["last_cancelled_at"],
                columnByName["last_modified_at"],
                columnByName["last_viewed"],
            ],
        },
    ]

    return [openEstimatesTabs, closedEstimatesTabs]
}
