import { ColumnId } from "@organisms/Table/Table.types"

export const sortingEnabledByColumnId: {
    [key in ColumnId]?: boolean
} = {
    id: true,
    client_name: true,
    state_label: false,
    status_label: true,
    service_name: true,
    service_location_address: true,
    service_location_name: true,
    total: true,
    date_due: true,
    down_payment_status: true,
    last_status_transition_time: false,
    last_sent_at: true,
    last_approved_at: true,
    last_won_at: true,
    last_lost_at: true,
    last_cancelled_at: true,
    last_modified_at: true,
    last_viewed: true,
    job_type: false,
    assigned_technicians: false,
    date_received: true,
    estimated_arrival_time: true,
    estimated_duration: true,
    last_scheduled_at: true,
    last_active_at: true,
    last_paused_at: true,
    last_on_hold_at: true,
    last_completed_at: true,
    last_finalized_at: true,
    amount_due: true,
    last_paid_at: true,
    last_voided_at: true,
    last_forgiven_at: true,
    vendor_name: true,
    item_count: true,
    subtotal: true,
    shipping: false,
    preferred_shipping: false,
    tracking_number: false,
    associated_jobs: false,
    date_issued: true,
    payment_terms: false,
    last_acknowledged_at: true,
    last_in_transit_at: true,
    last_delivered_at: true,
    last_stocked_at: true,
    name: true,
    client_type_label: true,
    service_locations_display: false,
    default_invoice_net: true,
    credit_limit: true,
    outstanding_balance: true,
    current_estimates_count: true,
    current_jobs_count: true,
    current_invoices_count: true,
    description: true,
    summary_template: false,
    cost: true,
    rate: true,
    tax_rate: true,
    discount: true,
    default_price: true,
    manufacturer: true,
    part_number: true,
    default_job_duration: true,
    labels: false,
    inventory_status: false,
    account_id: true,
    billing_address_formatted: true,
    website: false,
    notes: true,
    current_purchase_order_count: true,
}
