import { InvalidateQueryFilters, useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"

import toast from "@molecules/Toast/Toast"

import { NOTIFICATION_ENDPOINTS } from "@endpoints/notification"

import {
    NotificationPreferenceTopics,
    NotificationTopicsMap,
    NotificationTopicsType,
    NotificationTopicsValue,
} from "./SettingsNotification.types"
import { patchNotificationPreference } from "./SettingsNotifications.utils"

export const notificationPreferenceQueryKey = [
    "list-notification-methods",
    NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES,
]

const useNotificationPreferencesQuery = <T extends NotificationTopicsValue | undefined>(
    topic?: NotificationTopicsType,
): {
    isLoading: boolean
    topicPreference: T
    allTopics: NotificationTopicsMap
} => {
    const { data, isError, isLoading } = useQuery({
        queryKey: notificationPreferenceQueryKey,
        queryFn: async (): Promise<NotificationPreferenceTopics> => {
            const response = await fetch(NOTIFICATION_ENDPOINTS.RETRIEVE_NOTIFICATION_PREFERENCES)
            return (await response.json()) as NotificationPreferenceTopics
        },
    })

    const notificationTopicsTypes: NotificationTopicsMap = {
        inventory: {
            enable_inventory_notification_low_inventory_email:
                data?.enable_inventory_notification_low_inventory_email ?? false,
            enable_inventory_notification_low_inventory_sms:
                data?.enable_inventory_notification_low_inventory_sms ?? false,
        },
        jobs: {
            assignment: {
                enable_job_notification_job_assigned_email: data?.enable_job_notification_job_assigned_email ?? false,
                enable_job_notification_job_assigned_sms: data?.enable_job_notification_job_assigned_sms ?? false,
                enable_job_notification_job_unassigned_email:
                    data?.enable_job_notification_job_unassigned_email ?? false,
                enable_job_notification_job_unassigned_sms: data?.enable_job_notification_job_unassigned_sms ?? false,
                enable_job_notification_job_updated_email: data?.enable_job_notification_job_updated_email ?? false,
                enable_job_notification_job_updated_sms: data?.enable_job_notification_job_updated_sms ?? false,
                enable_job_notification_job_rescheduled_email:
                    data?.enable_job_notification_job_rescheduled_email ?? false,
                enable_job_notification_job_rescheduled_sms:
                    data?.enable_job_notification_job_rescheduled_sms ?? false,
                enable_job_notification_job_unscheduled_email:
                    data?.enable_job_notification_job_unscheduled_email ?? false,
                enable_job_notification_job_unscheduled_sms:
                    data?.enable_job_notification_job_unscheduled_sms ?? false,
                enable_job_notification_job_cancelled_email:
                    data?.enable_job_notification_job_cancelled_email ?? false,
                enable_job_notification_job_cancelled_sms: data?.enable_job_notification_job_cancelled_sms ?? false,
            },
            manager: {
                enable_job_notification_new_job_request_email:
                    data?.enable_job_notification_new_job_request_email ?? false,
                enable_job_notification_new_job_request_sms:
                    data?.enable_job_notification_new_job_request_sms ?? false,
                enable_job_notification_job_started_email: data?.enable_job_notification_job_started_email ?? false,
                enable_job_notification_job_started_sms: data?.enable_job_notification_job_started_sms ?? false,
                enable_job_notification_job_paused_email: data?.enable_job_notification_job_paused_email ?? false,
                enable_job_notification_job_paused_sms: data?.enable_job_notification_job_paused_sms ?? false,
                enable_job_notification_job_placed_on_hold_email:
                    data?.enable_job_notification_job_placed_on_hold_email ?? false,
                enable_job_notification_job_placed_on_hold_sms:
                    data?.enable_job_notification_job_placed_on_hold_sms ?? false,
                enable_job_notification_job_resumed_email: data?.enable_job_notification_job_resumed_email ?? false,
                enable_job_notification_job_resumed_sms: data?.enable_job_notification_job_resumed_sms ?? false,
                enable_job_notification_job_completed_email:
                    data?.enable_job_notification_job_completed_email ?? false,
                enable_job_notification_job_completed_sms: data?.enable_job_notification_job_completed_sms ?? false,
                enable_job_notification_job_summary_updated_email:
                    data?.enable_job_notification_job_summary_updated_email ?? false,
                enable_job_notification_job_summary_updated_sms:
                    data?.enable_job_notification_job_summary_updated_sms ?? false,
            },
        },
        estimates: {
            enable_estimate_notification_estimate_approved_sms:
                data?.enable_estimate_notification_estimate_approved_sms ?? false,
            enable_estimate_notification_estimate_approved_email:
                data?.enable_estimate_notification_estimate_approved_email ?? false,
            enable_estimate_notification_estimate_declined_sms:
                data?.enable_estimate_notification_estimate_declined_sms ?? false,
            enable_estimate_notification_estimate_declined_email:
                data?.enable_estimate_notification_estimate_declined_email ?? false,
        },
    }

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not retrieve notification preference",
            })
        }
    }, [isError])

    return {
        topicPreference: (topic ? notificationTopicsTypes[topic] : undefined) as T,
        allTopics: notificationTopicsTypes,
        isLoading,
    }
}

const useNotificationPreferencesMutation = () => {
    const client = useQueryClient()

    const { mutate, isError } = useMutation<NotificationPreferenceTopics, Error, NotificationPreferenceTopics>({
        mutationFn: patchNotificationPreference,
        onSuccess: () => {
            void client.invalidateQueries([notificationPreferenceQueryKey] as InvalidateQueryFilters)
        },
    })

    useEffect(() => {
        if (isError) {
            toast({
                type: "error",
                size: "md",
                title: "Could not update notification preference",
            })
        }
    }, [isError])

    return { mutate, isError }
}

export { useNotificationPreferencesMutation, useNotificationPreferencesQuery }
